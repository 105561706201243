import { useEffect } from "react";

const importScript = (url: string, cleanup: () => void) => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = url;
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
            cleanup();
        };
    }, [url, cleanup]);
};

export default importScript;
