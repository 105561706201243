import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import * as React from "react";
import * as yup from "yup";
import importScript from "../utils/importScript";
import axios, { AxiosError } from "axios";
import { navigate } from "gatsby";

declare global {
    interface Window {
        grecaptcha: ReCaptchaInstance;
    }
}

interface ReCaptchaInstance {
    ready: (cb: () => any) => void;
    execute: (id: string, options: ReCaptchaExecuteOptions) => Promise<string>;
}

interface ReCaptchaExecuteOptions {
    action: string;
}

interface SubscribeData {
    token: string;
    email: string;
}

const validationSchema = yup.object({
    email: yup
        .string()
        .email("Enter a valid email")
        .required("Email is required"),
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            display: "flex",
            justifyContent: "center",
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        field: {
            flexGrow: 1,
            maxWidth: 400,
        },
        submit: {
            marginLeft: theme.spacing(2),
        },
        btn: {
            paddingTop: 7,
            paddingBottom: 7,
        },
    })
);

const reCAPTCHA_site_key = "6LfsWfQZAAAAAMHAiLR87vtN4peOxFxgHNok2fOj";
const reCAPCTCHA_URL = `https://www.google.com/recaptcha/api.js?render=${reCAPTCHA_site_key}`;

interface SignUpFormControlProps {}

const SignUpFormControl: React.FC<SignUpFormControlProps> = (props) => {
    const classes = useStyles();
    importScript(reCAPCTCHA_URL, () => {
        const badges = Array.from(
            document.querySelectorAll(".grecaptcha-badge")
        );

        if (badges != null) {
            badges.map((badge) => document.body.removeChild(badge.parentNode!));
        }
    });

    return (
        <Formik
            initialValues={{
                email: "",
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
                window.grecaptcha.ready(() => {
                    window.grecaptcha
                        .execute(reCAPTCHA_site_key, { action: "signup" })
                        .then((token: string) => {
                            var data = {
                                token: token,
                                email: values.email,
                            };
                            axios
                                .post(
                                    `${process.env.GATSBY_API_DOMAIN}/api/subscribe`,
                                    data
                                )
                                .then((res) => {
                                    console.log(res);
                                    actions.resetForm();
                                    navigate("/signup-confirmed");
                                })
                                .catch((error: AxiosError) => {
                                    actions.setFieldError(
                                        "email",
                                        "Enter a valid email"
                                    );
                                    actions.setSubmitting(false);
                                });
                        });
                });
            }}
        >
            {({ submitForm, isSubmitting }) => (
                <Form className={classes.form}>
                    <div className={classes.field}>
                        <Field
                            component={TextField}
                            name="email"
                            type="email"
                            label="Email"
                            fullWidth
                            variant="outlined"
                            size="small"
                            id="email-input"
                        />
                    </div>
                    <div className={classes.submit}>
                        <Button
                            variant="contained"
                            disabled={isSubmitting}
                            onClick={submitForm}
                            className={classes.btn}
                        >
                            Subscribe
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default SignUpFormControl;
